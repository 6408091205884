html {
  scroll-behavior: smooth;
}

@font-face {
    font-family: "Pathos";
    src: url("/fonts/Pathos-Regular") format("ttf");
}

.app-text {
    padding: 1rem 4rem;
}

.app-content {
    text-align: center;
    background: #343a40;
    color: white;
    font-family: "Pathos";
}


.image {
    width: 100%;
    height: 100%;
    background: #DDDDDD;
}

input.custom, textarea.custom {
    background: inherit;
    border-radius: 0;
    outline: 0;
    border-width: 0 0 2px;
    border-color: white;
    color: inherit;
    margin: 1rem;
    resize: none;
}

#arrow-top {
    color: #343a40;
    position: fixed;
    bottom: 20px;
    left: 10px;
    cursor: pointer;
}

@media (min-width: 1400px) {
    .page {
        height: 75vh;
    }
}



.page-info {
    background: #303a40;
}

.inner-page-info {
    width: 100%;
}

.page-image {
    height: inherit;
}


.link {
    text-decoration: none;
    margin: 1rem;
}

.url-link {
    color: coral;
    text-decoration: none;
}

.form-button {
    border-radius: 0;
    border: 0;
    width: 98%;
    height: 40px;
}

ul.module-info-list {
    list-style-type: square;
    text-align: justify;
    font-size: 1.2em;

}

.error {
    border-color: #FF0000;
    color: #FF0000;
}

