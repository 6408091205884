.sidebar {
    position: fixed;
    top: 1%;
    right: 0;
    margin: 0;
    height: 100%;
    z-index: 1;
}

@media (max-width: 990px) {
    .sidebar {
        visibility: hidden
    }
}

.chosen-item {
    background: #7CB248;
}

ul {
    list-style-type: none;
}

.link {
    padding: 1rem;
}

.item {
    border: 2px solid #7CB248;
    width: 25px;
    height: 25px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 30px;
}