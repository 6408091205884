.phone {
    margin-top: 1rem;
    font-size: 2rem;
    font-weight: bold;
}

.email {
    margin-top: 1rem;
    font-size: 2rem;
    font-weight: bold;
}

.address {
    margin-top: 1rem;
    font-size: 2rem;
}

.our-links {
    margin-top: 3rem;
}

#fpi-logo {
    margin: 1.5rem 2rem;
}

#sgu-logo {
    width: 9.5%;
    height: 9.5%;
}

#sgmu-logo {
    width: 11%;
    height: 11%;
    margin-left: 1rem;
}


#ContactsForm {
    padding: 5rem;
}

@media (min-width: 768px) {
    #ContactsForm {
        grid-area: ContactsForm;
        display: grid;
        grid-template-areas: "contact-form-login contact-form-password" "contact-form-login-error contact-form-password-error" "contact-form-lastName contact-form-name" "contact-form-lastName-error contact-form-name-error" "contact-form-patronymic contact-form-organization" "contact-form-email contact-form-phone" "contact-form-email-error contact-form-phone-error" "contact-form-city contact-form-city" "contact-form-button contact-form-button" "confidential confidential";
        grid-template-rows: 60px;
        grid-auto-columns: 1fr 1fr;
    }

    #contact-form-login {
        grid-area: contact-form-login;
    }

    #contact-form-password {
        grid-area: contact-form-password;
    }

    #contact-form-login-error {
        grid-area: contact-form-login-error;
    }

    #contact-form-password-error {
        grid-area: contact-form-password-error;
    }

    #contact-form-name {
        grid-area: contact-form-name;
    }

    #contact-form-lastName {
        grid-area: contact-form-lastName;
    }


    #contact-form-name-error {
        grid-area: contact-form-name-error;
    }

    #contact-form-lastName-error {
        grid-area: contact-form-lastName-error;
    }


    #contact-form-patronymic {
        grid-area: contact-form-patronymic;
    }

    #contact-form-email {
        grid-area: contact-form-email;
    }

    #contact-form-email-error {
        grid-area: contact-form-email-error;
    }

    #contact-form-phone {
        grid-area: contact-form-phone;
    }

    #contact-form-phone-error {
        grid-area: contact-form-phone-error;
    }

    #contact-form-city {
        grid-area: contact-form-city;
    }

    #contact-form-organization {
        grid-area: contact-form-organization;
    }

    #contact-form-button {
        grid-area: contact-form-button;
    }

    #confidential {
        grid-area: confidential;
    }


}

