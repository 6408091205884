#logo{
   height: 2rem;
}

.header-navbar{
   font-size: 1.5rem;
}

.header-link{
   color: #D0D0D0 !important;
}

.header-link:hover{
   color: white !important;
}

.dropdown-item.active, .dropdown-item:active{
   background-color: grey !important;
}
