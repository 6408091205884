
#main-button-box {
    height: inherit;
}

#download-button {
    display: inline-block;
    font-size: 2em;
    border-radius: 30px;
    border: none;
    outline: none;
    text-align: center;
    text-decoration: none;
    margin-top: 80vh;
    box-shadow: 0 15px 20px grey;
}