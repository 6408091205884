.flow-box {
    background: white;
    width: 15vw;
    height: 15vw;
}


#flow-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    vertical-align: middle;
    padding: 5%;
}